@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
    background: #f7fafc;
}

.loading {
    width: 100vw;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.helper {
    color: #fff;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    width: 12px;
    height: 12px;
    font-size: 10px;
    justify-content: center;
    position: relative;
    top: -4px;
}

p {
    margin-bottom: 1rem;
}

.ais-SearchBox-submit, .ais-SearchBox-reset {
    display: none
}

table {

    th, td {
        text-align: center;
        padding: 5px;

        &.small {
            width: calc(100% / 7);
        }

        &.xs {
            width: calc(100% / 20);
        }
    }

    input, select {
        width: 100%;
        -webkit-appearance: none;
        padding: 20px;
        text-align: center;
    }

    tbody {
        th {
            vertical-align: middle;
            text-align: left;
        }
    }
}

input[type=text],
input[type=search],
input[type=password],
select{
    -webkit-appearance: none;
    width: 100%;
    padding: 10px;

    &:focus {
        outline: none;
    }
}

#dataApp {
    @media(min-width: 993px){
        min-height: calc(100vh - 230px);
    }
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

h1, h2, h3, h4, h5, h6 {
    font-family: "IBM Plex Sans", sans-serif;
}

@media print {

}

.card {
    box-shadow: -5px 15px 25px #0f172a1a;

    .canvas-container {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        max-height: 500px;
        height: 100%!important;
    }
}

.chevron::before {
    content: "";
    height: 11px;
    vertical-align: top;
    width: 11px;
    border: 0.15em solid #fff;
    border-width: 0.15em 0.15em 0 0;
    display: inline-block;
    position: relative;
    top: 10px;
    right: 4px;
    transform: rotate(-45deg);
}

.chevron.bottom:before {
    top: 4px;
    transform: rotate(135deg);
}

.checked {

    &:after {
        content: "";
        height: 14px;
        width: 7px;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

.password-strength-indicator {
    position: relative;

    &:after {
        content: '';
        background: rgb(220 38 38);
        height: 3px;
        position: absolute;
        bottom: -1px;
        left: 0;
        transition: .5s all;
    }

    &.strength-0:after {
        width: 10%;
    }

    &.strength-1:after {
        width: 25%;
    }

    &.strength-2:after {
        width: 50%;
        background: #d60;
    }

    &.strength-3:after {
        width: 75%;
        background: #f4ba16;
    }

    &.strength-4:after {
        width: 100%;
        background: #2f855a;
    }
}

[disabled] {
    cursor: not-allowed;

    &.btn {
        background: #e2e8f0 !important;
        color: #a0aec0 !important;
        border-color: #e2e8f0 !important;
    }
}

.progress-indicator {
    position: sticky;
    border-top: 3px solid #f7fafc;
    z-index: 23;

    &:before {
        content: '';
        background: #2f855a;
        height: 3px;
        position: absolute;
        top: -3px;
        left: 0;
        transition: .5s all;
        width: 25%;
    }

    &.progress-2 {
        &:before {
            width: 50%;
        }
    }

    &.progress-3 {
        &:before {
            width: 75%;
        }
    }
}

.bg-linkedin {
    background: #0072b1;
}